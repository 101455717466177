<template>
    <List ref="list" :moduleId="moduleId" :threeType="threeType" :resourceName="resourceName"></List>
</template>

<script>
    import List from '../components/list' // 列表
    export default {
        components: {List},
        data() {
            return {
                moduleId: "946049994480156672",
                threeType: 1,
                resourceName: "图片资源",
            }
        },
        mounted() {
            this.$refs.list.init(this.threeType)
        },
        methods: {}
    }
</script>
